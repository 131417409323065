import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const ITEM_SCALE_SELECTOR = '.js-item-scale'
const TECHNOLOGY_SELECTOR = '.js-technology-video'
const DOT_SELECTOR = '.js-dot-item'
const PARENT_DOT_SELECTOR = '.wrapper-dot'

export default () => {
  // spacing is the distance from edge to module, see class .px-responsive
  const itemScaleEl = document.querySelector(ITEM_SCALE_SELECTOR)
  const technologyEl = document.querySelector(TECHNOLOGY_SELECTOR)
  const parentDotEls = document.querySelectorAll(PARENT_DOT_SELECTOR)

  const getScaleInit = (spacing = 15) => {
    const maxWidth = 1600 - 80
    const windowWidth = window.innerWidth
    const width = windowWidth - spacing * 2
    const widthInit = width > maxWidth ? maxWidth : width
    return widthInit / windowWidth
  }

  const getDistanceToParentBottom = (el, parent) => Math.abs(parent.getBoundingClientRect().bottom - el.getBoundingClientRect().bottom)

  const scaleAnimation = (elm, spacing) => {
    const scaleInit = getScaleInit(spacing)
    const siteHeader = document.querySelector('[data-section="header"]')
    const headerHeight = siteHeader ? siteHeader.clientHeight : 0
    const endPosition = 100 * (headerHeight + 3) / window.innerHeight
    gsap.from(elm, {
      scrollTrigger: {
        trigger: elm,
        scrub: true,
        start: 'top bottom',
        end: `top ${endPosition}%`,
      },
      scaleX: scaleInit,
      transformOrigin: 'top center',
      ease: 'none',
    })
  }

  const shrinkAnimation = (elm, spacing) => {
    const scaleInit = getScaleInit(spacing)
    gsap.to(elm, {
      scrollTrigger: {
        trigger: elm,
        scrub: true,
        start: 'bottom 50%',
        end: 'bottom top',
      },
      scaleX: scaleInit,
      transformOrigin: 'top center',
      ease: 'none',
    })
  }

  const backgroundPositionAnimation = (elm, parent) => {
    const distance = getDistanceToParentBottom(elm, parent)
    const windowHeight = window.innerHeight
    const endPosition = 100 * (windowHeight - distance - (windowHeight / 2)) / windowHeight
    gsap.to(elm, {
      scrollTrigger: {
        trigger: elm,
        scrub: true,
        start: 'top bottom',
        end: `top ${endPosition}%`,
      },
      backgroundPosition: '100% 100%',
      ease: 'none'
    })
  }

  ScrollTrigger.matchMedia({
    '(min-width: 1440px)': function() {
      if (itemScaleEl) {
        shrinkAnimation(ITEM_SCALE_SELECTOR, 40)
        scaleAnimation(ITEM_SCALE_SELECTOR,40)
      }
      if (technologyEl) {
        shrinkAnimation(TECHNOLOGY_SELECTOR, 40)
        scaleAnimation(TECHNOLOGY_SELECTOR, 40)
      }
      if (parentDotEls.length) {
        parentDotEls.forEach(parentDotEl => {
          const dotEls = parentDotEl.querySelectorAll(DOT_SELECTOR)
          if (dotEls && dotEls.length) {
            setTimeout(function () {
              dotEls.forEach(dotEl => {
                backgroundPositionAnimation(dotEl, parentDotEl)
              })
            }, 500)
          }
        })
      }
    },
    '(min-width: 1024px) and (max-width: 1439px)': function() {
      if (itemScaleEl) {
        shrinkAnimation(ITEM_SCALE_SELECTOR, 30)
        scaleAnimation(ITEM_SCALE_SELECTOR, 30)
      }
      if (technologyEl) {
        shrinkAnimation(TECHNOLOGY_SELECTOR, 30)
        scaleAnimation(TECHNOLOGY_SELECTOR, 30)
      }
      if (parentDotEls.length) {
        parentDotEls.forEach(parentDotEl => {
          const dotEls = parentDotEl.querySelectorAll(DOT_SELECTOR)
          if (dotEls && dotEls.length) {
            setTimeout(function () {
              dotEls.forEach(dotEl => {
                backgroundPositionAnimation(dotEl, parentDotEl)
              })
            }, 500)
          }
        })
      }
    },
    '(min-width: 768px) and (max-width: 1023px)': function() {
      if (itemScaleEl) {
        shrinkAnimation(ITEM_SCALE_SELECTOR, 20)
        scaleAnimation(ITEM_SCALE_SELECTOR, 20)
      }
      if (technologyEl) {
        shrinkAnimation(TECHNOLOGY_SELECTOR, 20)
        scaleAnimation(TECHNOLOGY_SELECTOR, 20)
      }
      if (parentDotEls.length) {
        parentDotEls.forEach(parentDotEl => {
          const dotEls = parentDotEl.querySelectorAll(DOT_SELECTOR)
          if (dotEls && dotEls.length) {
            setTimeout(function () {
              dotEls.forEach(dotEl => {
                backgroundPositionAnimation(dotEl, parentDotEl)
              })
            }, 500)
          }
        })
      }
    },
    '(max-width: 767px)': function() {
      if (itemScaleEl) {
        shrinkAnimation(ITEM_SCALE_SELECTOR, 15)
        scaleAnimation(ITEM_SCALE_SELECTOR, 15)
      }
      if (technologyEl) {
        shrinkAnimation(TECHNOLOGY_SELECTOR, 15)
        scaleAnimation(TECHNOLOGY_SELECTOR, 15)
      }
      if (parentDotEls.length) {
        parentDotEls.forEach(parentDotEl => {
          const dotEls = parentDotEl.querySelectorAll(DOT_SELECTOR)
          if (dotEls && dotEls.length) {
            setTimeout(function () {
              dotEls.forEach(dotEl => {
                backgroundPositionAnimation(dotEl, parentDotEl)
              })
            }, 500)
          }
        })
      }
    }
  });
}
