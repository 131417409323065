const INPUT_ACTIVE_CLASS = 'input-active'
const FORM_SELECTOR = '.js-blog-form'
let numIndex = 0

const formEl = Array.prototype.slice.call(document.querySelectorAll(FORM_SELECTOR))
if (formEl) {
  const focusInHandler = (event) => {
    const componentEl = event.target.closest('[data-testid="form-component"]')
    if (componentEl) {
    componentEl.classList.add(INPUT_ACTIVE_CLASS)
    }
  }

  const focusOutHandler = (event) => {
    const componentEl = event.target.closest('[data-testid="form-component"]')
    if (componentEl && componentEl.classList.contains(INPUT_ACTIVE_CLASS) && event.target.value === '') {
    componentEl.classList.remove(INPUT_ACTIVE_CLASS)
    }
  }

  const addAnimation = (inputEls) => {
    if (inputEls.length) {
      inputEls.forEach(inputEl => {
        if (inputEl && inputEl.addEventListener){
        inputEl.addEventListener("focus", focusInHandler, true)
        inputEl.addEventListener("blur", focusOutHandler, true)
        }
      })
    }
  }

  const observer = new window.MutationObserver((mutations) => {
    if (mutations.length > 0) {
      const inputEmails = document.querySelectorAll('[type="email"]')
      addAnimation(inputEmails)
    }
  })

  formEl.map(el => {
    observer.observe(el, { attributes: false, childList: true, subtree: true })
  })
}

const isValidEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

window.addEventListener('klaviyoForms', (e) => {
  if (e.detail.type == 'open' || e.detail.type == 'embedOpen') {
    setTimeout(() => {
      const formSelector = `form[data-testid="klaviyo-form-${e.detail.formId}"]`
      const formEls = document.querySelectorAll(formSelector)
  
      formEls.forEach((formEl) => {
        if (formEl.dataset.listenDatalayer === 'true') return

        const formName = formEl.closest('div.klaviyo-form').getAttribute('data-gtm-form-name') ? formEl.closest('div.klaviyo-form').getAttribute('data-gtm-form-name') : 'Newsletter Form'
        const btnEl = formEl.querySelector('button')
        const inputEl = formEl.querySelector('input[type="email"]')

        btnEl.addEventListener('click', () => {
          if (isValidEmail.test(inputEl.value)) {
            dataLayer.push({
              event: "form_submission",
              params: {
                form_name: formName,
                subject: "newsletter signup"
              }
            })
          }
        })
        formEl.dataset.listenDatalayer = true
      })
    }, 1000)
  }
})
